:root {
  --s-textcolor: #000000;
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 20px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 10px;
  }
}
:root {
  --spaceTotal: 30px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 20px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
h1 {
  font-size: 60px;
  line-height: 1;
}
h2 {
  font-size: 40px;
  line-height: 1.125;
}
h3 {
  font-size: 30px;
  line-height: 1.16666667;
}
h4 {
  font-size: 24px;
  line-height: 1.20833333;
}
h5 {
  font-size: 18px;
  line-height: 1.27777778;
}
p.loud {
  font-size: 24px;
  line-height: 1.33333333;
}
/*# sourceMappingURL=./screen-medium.css.map */